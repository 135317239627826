// Packages
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Container, Row, Col, Table, Spinner, Button, Input, InputGroup } from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { BsPencilFill, BsTrashFill, BsArrowRepeat, BsSearch } from "react-icons/bs";
// Utils
import { catchHandler, getRequestHeaders, userRoleToLabel } from "../utils";
// Components
import Pagination from "../components/Pagination"
// Styling
import "./UsersManagement.css";

const UsersManagement = React.memo((props) => {
  const { state: locationState = {} } = useLocation();
  const { pageNumber = 1 } = locationState;
  const [loading, setLoading] = useState(true);
  const [loadingActions, setLoadingActions] = useState(false);
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(pageNumber);
  const [totalCount, setTotalCount] = useState(0);
  const history = useHistory();
  const catchHandlerBinded = catchHandler.bind(null, history);
  const [searchValue, setSearchValue] = useState('');

  const getData = useCallback((currentPage, searchValue) => {
    axios
      .get(`/api/users/list-users`, getRequestHeaders(Object.assign({
        page: currentPage
      }, searchValue && {
        search: searchValue
      })))
      .then((res) => {
        const { data = [] } = res;
        const { items = [], totalCount = 0 } = data;
        setUsers(items);
        setTotalCount(totalCount);
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getData(currentPage);
  }, [currentPage]);

  const onResetClick = useCallback(
    (email) => {
      setLoadingActions(true);

      axios
        .post("/api/auth/reset-password", { email })
        .then((res) => {
          const { email } = res.data;
          toast.success(`Un email cu un link de resetare parola a fost trimis catre adresa [${email}].`);
        })
        .catch(catchHandlerBinded)
        .finally(() => {
          setLoadingActions(false);
        });
    },
    []
  );

  const onEditClick = useCallback(
    (id) => {
      history.push(`${history.location.pathname}/edit/${id}`);
    },
    [history]
  );

  const onRemoveClick = useCallback(
    (id, currentPage) => {
      setLoadingActions(true);

      axios
        .delete(`/api/users/remove-user/${id}`, {}, getRequestHeaders())
        .then((res) => {
          const { data = {} } = res;
          const { name = "" } = data;
          toast.success(`Utilizatorul [${name}] a fost sters cu succes`);
          getData(currentPage);
        })
        .catch(catchHandlerBinded)
        .finally(() => {
          setLoadingActions(false);
        });
    },
    []
  );

  const getRows = useCallback(
    () =>
      users.length ? users.map(
        ({ _id, name, email, role, blocked }, id) => (
          <tr key={`users-row-${id}`}>
            <td>{name}</td>
            <td>{email}</td>
            <td>{userRoleToLabel[role]}</td>
            <td>{blocked ? "Inactiv" : "Activ"}</td>
            <td className="text-center">
              <Button
                disabled={loadingActions}
                color="info"
                size="sm"
                onClick={onResetClick.bind(null, email)}
              >
                <BsArrowRepeat />
              </Button>
            </td>
            <td className="text-center">
              <Button
                disabled={loadingActions}
                color="warning"
                size="sm"
                onClick={onEditClick.bind(null, _id)}
              >
                <BsPencilFill />
              </Button>
            </td>
            <td className="text-center">
              <Button
                disabled={loadingActions}
                color="danger"
                size="sm"
                onClick={onRemoveClick.bind(null, _id, currentPage)}
              >
                <BsTrashFill />
              </Button>
            </td>
          </tr>
        )
      ) : (
        <tr>
          <td colSpan={7} className="text-center">
            <span>Fara rezultat</span>
          </td>
        </tr>
      ),
    [users, loadingActions]
  );

  const onSearchChange = useCallback((event) => {
    const { target = {} } = event;
    const { value } = target;
    setSearchValue(() => value);
  }, []);

  const handleSearch = useCallback((event) => {
    const { type, key } = event;

    if(type === 'click' || (type === 'keypress' && key === 'Enter')) {
      getData(1, searchValue)
    }
  }, [searchValue]);

  return (
    <Container className="mt-5 mb-5" style={{ maxWidth: "1400px" }}>
      <Row className="mt-5">
        <Col className="d-flex justify-content-between align-items-center">
          <span className="">
            <h3>Utilizatori ({totalCount})</h3>
          </span>
        </Col>
      </Row>
      <Row className="mt-1 justify-content-end">
        <Col className="col-3 d-inline-flex">
          <InputGroup>
            <Input
              type="text"
              placeholder="Search..."
              onChange={onSearchChange}
              onKeyPress={handleSearch}
              value={searchValue}
            />
            <Button
              disabled={loadingActions || loading}
              color="secondary"
              size="sm"
              onClick={handleSearch}
            >
              <BsSearch />
            </Button>
          </InputGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col>
          <Table hover>
            <thead>
              <tr>
                <th>Nume</th>
                <th>Email</th>
                <th>Rol</th>
                <th>Stare</th>
                <th className="text-center">Resetare parola</th>
                <th className="text-center">Modificare</th>
                <th className="text-center">Stergere</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={7} className="text-center">
                    <Spinner color="dark" style={{ width: "3rem", height: "3rem" }} />
                  </td>
                </tr>
              ) : (
                getRows()
              )}
            </tbody>
          </Table>
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            onSetPage={setCurrentPage}
          />
        </Col>
      </Row>
    </Container>
  )
});

export default UsersManagement;
