// Packages
import React from "react";
import { Route } from "react-router-dom";
// Utils
import { isAuthenticated, userHasAccess } from "../utils";

const FallbackRoute = React.memo(
  ({ main: Main, second: Second, fallback: Fallback, accessRoles = [], ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          userHasAccess(accessRoles) ? <Main {...props} /> : <Second {...props} />
        ) : <Fallback {...props} />
      }
    />
  )
);

export default FallbackRoute;
