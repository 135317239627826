// Packages
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
} from "reactstrap";
import axios from "axios";
// Utils
import {
  catchHandler,
  getRequestHeaders,
  reportsConfigsTypeToLabel,
} from "../utils";
// Styling
import "./EditReport.css";

const EditReport = React.memo((props) => {
  const { id: reportId } = useParams();
  const { state: locationState = {} } = useLocation();
  const { rootPathname = "/reports", pageNumber = 1 } = locationState;
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState({
    name: "",
    type: "",
    emplacement: ""
  });
  const [sections, setSections] = useState([{ name: "", fields: [] }]);
  const history = useHistory();
  const catchHandlerBinded = catchHandler.bind(null, history);

  useEffect(() => {
    axios
      .get(`/api/reports/get-report/${reportId}`, getRequestHeaders())
      .then((res) => {
        const { data = {} } = res;
        const { sections = [], emplacement = {}, name = "", type = "" } = data;
        setDetails({
          name,
          type: reportsConfigsTypeToLabel[type],
          emplacement: emplacement.name
        });
        setSections(sections);
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();

      setLoading(true);

      axios
        .patch(`/api/reports/edit-report/${reportId}`, { sections }, getRequestHeaders())
        .then((res) => {
          const { data = {} } = res;
          const { name = "" } = data;
          toast.success(`Raportul [${name}] a fost modificat cu succes`);
          history.push(rootPathname);
        })
        .catch((err) => {
          catchHandler(history, err);
          setLoading(false);
        });
    },
    [history, sections, rootPathname]
  );

  const onCancel = useCallback(() => {
    history.push(rootPathname, { pageNumber });
  }, [history, rootPathname, pageNumber]);

  const onSectionFieldVallueChange = useCallback((sectionId, fieldId, event) => {
    const { target = {} } = event;
    const { value } = target;

    setSections(prevSections => {
      const newSections = [...prevSections];
      newSections[sectionId].fields[fieldId].value = value;
      return newSections;
    });
  }, []);

  let fieldsCounter = 0;

  return (
    <Container className="mt-5 mb-5">
      <Row>
        <Col sm="12" md={{ size: 10, offset: 1 }}>
          <Card className="p-5 bg-light shadow-sm">
            <h2 className="mx-auto text-center">Modificare Raport</h2>
            <h4 className="mx-auto text-center">{details.name} [{details.type}]</h4>
            <h6 className="mb-5 mx-auto text-center">({details.emplacement})</h6>
            <Form>
              {
                sections.map((section, sectionId) => (
                  <React.Fragment key={`section-row-key-${sectionId}`}>
                    {sectionId > 0 ? ( <hr/> ) : null}
                    <Label><strong>{sectionId + 1}. {section.name}</strong></Label>
                    {
                      section.fields.map(({ name, value, canModifyFlag }, fieldId) => (
                        <FormGroup className="form-group-relative" key={`section-${sectionId}-field-key-${fieldId}`}>
                          <Label>{++fieldsCounter}) {name}</Label>
                          <Input
                            type="textarea"
                            value={value}
                            disabled={!canModifyFlag}
                            onChange={onSectionFieldVallueChange.bind(null, sectionId, fieldId)}
                          />
                        </FormGroup>
                      ))
                    }
                  </React.Fragment>
                ))
              }
              <FormGroup className="mt-5">
                <Button
                  color="primary"
                  className="float-right"
                  onClick={onSubmit}
                  disabled={loading}
                >
                  Salvare
                </Button>
                <Button
                  outline
                  color="secondary"
                  className="float-left"
                  onClick={onCancel}
                >
                  Renuntare
                </Button>
              </FormGroup>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  )
});

export default EditReport;
