// WARNING: keep this value in sync with the server value
export const reportsConfigsTypes = {
  MONTHLY: "monthly",
  QUARTERLY: "quarterly",
  YEARLY: "yearly",
};

export const reportsConfigsTypeToLabel = {
  [reportsConfigsTypes.MONTHLY]: "Lunar",
  [reportsConfigsTypes.QUARTERLY]: "Trimestrial",
  [reportsConfigsTypes.YEARLY]: "Anual",
};

export const reportsConfigsTypeOptions = {
  [reportsConfigsTypes.MONTHLY]: { value: reportsConfigsTypes.MONTHLY, label: reportsConfigsTypeToLabel[reportsConfigsTypes.MONTHLY] },
  [reportsConfigsTypes.QUARTERLY]: { value: reportsConfigsTypes.QUARTERLY, label: reportsConfigsTypeToLabel[reportsConfigsTypes.QUARTERLY] },
  [reportsConfigsTypes.YEARLY]: { value: reportsConfigsTypes.YEARLY, label: reportsConfigsTypeToLabel[reportsConfigsTypes.YEARLY] }
};
