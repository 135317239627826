// Packages
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Button,
  Card,
} from "reactstrap";
import axios from "axios";
// Utils
import {
  catchHandler,
  getRequestHeaders,
  reportsConfigsTypeToLabel,
  getSelectStyles,
  processUserName
} from "../utils";
// Styling
import "./EditReportsConfigPermissions.css";

const EditReportsConfigPermissions = React.memo((props) => {
  const { id: reportConfigId } = useParams();
  const rootPathname = useMemo(() => "/reports-configs", []);
  const [loading, setLoading] = useState(true);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [details, setDetails] = useState({
    name: "",
    type: "",
  });
  const [sections, setSections] = useState([{ name: "", fields: [] }]);
  const [users, setUsers] = useState([]);
  const history = useHistory();
  const catchHandlerBinded = catchHandler.bind(null, history);

  useEffect(() => {
    axios
      .get(`/api/reports-configs/get-reports-config/${reportConfigId}`, getRequestHeaders())
      .then((res) => {
        const { data = {} } = res;
        const { sections = [], name = "", type = "" } = data;
        setDetails({
          name,
          type: reportsConfigsTypeToLabel[type],
        });
        setSections(sections);
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`/api/users/select-users-employee`, getRequestHeaders())
      .then((res) => {
        const { data = [] } = res;
        const usersOptions = {};
        data.forEach(({_id, name, email}) => {
          usersOptions[_id] = { value: _id, label: processUserName({name, email}) }
        });
        setUsers(usersOptions);
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoadingUsers(false);
      });
  }, []);

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();

      setLoading(true);

      axios
        .patch(`/api/reports-configs/edit-reports-config-permissions/${reportConfigId}`, { sections }, getRequestHeaders())
        .then((res) => {
          const { data = {} } = res;
          const { name = "" } = data;
          toast.success(`Permisiunile raportului [${name}] au fost modificate cu succes`);
          history.push(rootPathname);
        })
        .catch((err) => {
          catchHandler(history, err);
          setLoading(false);
        });
    },
    [history, sections, rootPathname]
  );

  const onCancel = useCallback(() => {
    history.push(rootPathname);
  }, [history, rootPathname]);

  const onMultiSelectChange = useCallback((sectionId, fieldId, options) => {
    setSections((prevSections) => {
      const currentSections = [...prevSections];
      currentSections[sectionId].fields[fieldId].users = options.map(({ value }) => value);
      return currentSections;
    });
  }, []);

  let fieldsCounter = 0;

  return (
    <Container className="mt-5 mb-5">
      <Row>
        <Col sm="12" md={{ size: 10, offset: 1 }}>
          <Card className="p-5 bg-light shadow-sm">
            <h2 className="mx-auto text-center">Modificare Permisiuni Raport</h2>
            <h4 className="mx-auto text-center">{details.name} [{details.type}]</h4>
            <Form>
              {
                sections.map((section, sectionId) => (
                  <React.Fragment key={`section-row-key-${sectionId}`}>
                    {sectionId > 0 ? ( <hr/> ) : null}
                    <Label><strong>{sectionId + 1}. {section.name}</strong></Label>
                    {
                      section.fields.map((field, fieldId) => (
                        <FormGroup className="form-group-relative" key={`section-${sectionId}-field-key-${fieldId}`}>
                          <Label>{++fieldsCounter}) {field.name}</Label>
                          <Select
                            name="user"
                            className={Boolean(false) ? "is-invalid" : ""}
                            placeholder="Alegeti un utilizator"
                            noOptionsMessage={() => (<span>Fara rezultat</span>)}
                            isClearable={true}
                            isMulti={true}
                            isLoading={loadingUsers}
                            onChange={onMultiSelectChange.bind(null, sectionId, fieldId)}
                            styles={getSelectStyles(Boolean(false))}
                            value={field.users.map(userId => users[userId])}
                            options={Object.values(users)}
                          />
                        </FormGroup>
                      ))
                    }
                  </React.Fragment>
                ))
              }
              <FormGroup className="mt-5">
                <Button
                  color="primary"
                  className="float-right"
                  onClick={onSubmit}
                  disabled={loading}
                >
                  Salvare
                </Button>
                <Button
                  outline
                  color="secondary"
                  className="float-left"
                  onClick={onCancel}
                >
                  Renuntare
                </Button>
              </FormGroup>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  )
});

export default EditReportsConfigPermissions;
