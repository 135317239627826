// Packages
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Button,
  Card,
  Input,
} from "reactstrap";
import axios from "axios";
// Utils
import {
  catchHandler,
  getRequestHeaders,
  reportsConfigsTypeToLabel,
} from "../utils";
// Styling
import "./EditReportsConfigSerialNumbers.css";

const EditReportsConfigSerialNumbers = React.memo((props) => {
  const { id: reportConfigId } = useParams();
  const rootPathname = useMemo(() => "/reports-configs", []);
  const [loading, setLoading] = useState(true);
  const [loadingEmplacements, setLoadingEmplacements] = useState(true);
  const [details, setDetails] = useState({
    name: "",
    type: "",
  });
  const [serialNumbers, setSerialNumbers] = useState({});
  const [emplacements, setEmplacements] = useState([]);
  const history = useHistory();
  const catchHandlerBinded = catchHandler.bind(null, history);

  useEffect(() => {
    axios
      .get(`/api/reports-configs/get-reports-config-serial-numbers/${reportConfigId}`, getRequestHeaders())
      .then((res) => {
        const { data = {} } = res;
        const { serialNumbers = [], name = "", type = "" } = data;
        const serialNumbersMap = {};
        serialNumbers.forEach(({ _id, number, emplacement }) => {
          serialNumbersMap[emplacement] = { _id, number };
        })
        setDetails({
          name,
          type: reportsConfigsTypeToLabel[type],
        });
        setSerialNumbers(serialNumbersMap);
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`/api/emplacements/select-emplacements`, getRequestHeaders())
      .then((res) => {
        const { data = [] } = res;
        setEmplacements(data);
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoadingEmplacements(false);
      });
  }, []);

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();

      setLoading(true);

      axios
        .patch(`/api/reports-configs/edit-reports-config-serial-numbers/${reportConfigId}`, { serialNumbers }, getRequestHeaders())
        .then((res) => {
          const { data = {} } = res;
          const { name = "" } = data;
          toast.success(`Numerele rapoartelor [${name}] au fost modificate cu succes`);
          history.push(rootPathname);
        })
        .catch((err) => {
          catchHandler(history, err);
          setLoading(false);
        });
    },
    [history, serialNumbers, rootPathname]
  );

  const onCancel = useCallback(() => {
    history.push(rootPathname);
  }, [history, rootPathname]);

  const onInputChange = useCallback((id, event) => {
    const { target = {} } = event;
    const { value } = target;
    setSerialNumbers((prevSerialNumbers) => {
      const currentSerialNumber = prevSerialNumbers[id] || {};
      currentSerialNumber.number = value;
      return {
        ...prevSerialNumbers,
        [id]: currentSerialNumber
      };
    });
  }, []);

  return (
    <Container className="mt-5 mb-5">
      <Row>
        <Col sm="12" md={{ size: 10, offset: 1 }}>
          <Card className="p-5 bg-light shadow-sm">
            <h2 className="mx-auto text-center">Modificare Numere Rapoarte</h2>
            <h4 className="mx-auto text-center">{details.name} [{details.type}]</h4>
            <Form>
              {
                emplacements.map(({ _id, name }, index) => (
                  <FormGroup className="form-group-relative" key={`serial-number-row-${index}`}>
                    <Label>{name}</Label>
                    <Input
                      id="serialNumber"
                      name="serialNumber"
                      type="number"
                      value={serialNumbers[_id] ? serialNumbers[_id].number : 0}
                      min={0}
                      onChange={onInputChange.bind(null, _id)}
                    />
                  </FormGroup>
                ))
              }
              <FormGroup className="mt-5">
                <Button
                  color="primary"
                  className="float-right"
                  onClick={onSubmit}
                  disabled={loading || loadingEmplacements}
                >
                  Salvare
                </Button>
                <Button
                  outline
                  color="secondary"
                  className="float-left"
                  onClick={onCancel}
                >
                  Renuntare
                </Button>
              </FormGroup>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  )
});

export default EditReportsConfigSerialNumbers;
