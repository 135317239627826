// Packages
import React, {
  useCallback,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  Card,
} from "reactstrap";
import axios from "axios";
// Utils
import { catchHandler, getRequestHeaders } from "../utils";
// Styling
import "./ChangePassword.css";

const ChangePassword = React.memo((props) => {
  const rootPathname = useMemo(() => "/profile", []);
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    password: "",
    password2: ""
  });
  const [errors, setErrors] = useState({});
  const history = useHistory();

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();

      setLoading(true);
      setErrors({});

      axios
        .patch("/api/users/change-password", fields, getRequestHeaders())
        .then(() => {
          toast.success("Parola a fost schimbata cu succes");
          history.push(rootPathname);
        })
        .catch((err) => {
          const { password = "", password2 = "" } = catchHandler(history, err);
          setErrors({ password, password2 });
          setLoading(false);
        });
    },
    [history, fields, rootPathname]
  );

  const onCancel = useCallback(() => {
    history.push(rootPathname);
  }, [history, rootPathname]);

  const onInputChange = useCallback((event) => {
    const { target = {} } = event;
    const { name, value } = target;
    setFields((prevFields) => ({ ...prevFields, [name]: value }));
  }, []);

  return (
    <Container className="mt-5 mb-5">
      <Row>
        <Col sm="12" md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
          <Card className="p-5 bg-light shadow-sm">
            <h2 className="mb-5 mx-auto text-center">Modificare Parola</h2>
            <Form>
              <FormGroup>
                <Label for="password">Password</Label>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="on"
                  invalid={Boolean(errors["password"])}
                  value={fields["password"]}
                  onChange={onInputChange}
                />
                <FormFeedback>{errors["password"]}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="password">Confirm Password</Label>
                <Input
                  id="password2"
                  name="password2"
                  type="password"
                  autoComplete="on"
                  invalid={Boolean(errors["password2"])}
                  value={fields["password2"]}
                  onChange={onInputChange}
                />
                <FormFeedback>{errors["password2"]}</FormFeedback>
              </FormGroup>
              <FormGroup className="mt-5">
                <Button
                  color="primary"
                  className="float-right"
                  onClick={onSubmit}
                  disabled={loading}
                >
                  Salvare
                </Button>
                <Button
                  outline
                  color="secondary"
                  className="float-left"
                  onClick={onCancel}
                >
                  Renuntare
                </Button>
              </FormGroup>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  )
});

export default ChangePassword;
