// Packages
import React, { useMemo, useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Container, Row, Col, Table, Spinner, Button, Input, InputGroup } from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { BsPencilFill, BsCheckLg, BsXLg, BsDownload, BsSearch } from "react-icons/bs";
// Utils
import {
  userHasAccess,
  userRoles,
  catchHandler,
  getRequestHeaders,
  reportsConfigsTypeToLabel
} from "../utils";
// Components
import Pagination from "../components/Pagination"
// Styling
import "./Reports.css";

const Reports = React.memo((props) => {
  const isAdminFlag = useMemo(() => userHasAccess([userRoles.ADMIN]), []);
  const isEmployeeFlag = useMemo(() => userHasAccess([userRoles.EMPLOYEE]), []);
  const { state: locationState = {} } = useLocation();
  const { pageNumber = 1 } = locationState;
  const [loading, setLoading] = useState(true);
  const [loadingActions, setLoadingActions] = useState(false);
  const [reports, setReports] = useState([]);
  const [currentPage, setCurrentPage] = useState(pageNumber);
  const [totalCount, setTotalCount] = useState(0);
  const history = useHistory();
  const catchHandlerBinded = catchHandler.bind(null, history);
  const [searchValue, setSearchValue] = useState('');

  const getData = useCallback((currentPage, searchValue) => {
    axios
      .get(`/api/reports/list-reports`, getRequestHeaders(Object.assign({
        page: currentPage
      }, searchValue && {
        search: searchValue
      })))
      .then((res) => {
        const { data = {} } = res;
        const { items = [], totalCount = 0 } = data;
        setReports(items);
        setTotalCount(totalCount);
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getData(currentPage);
  }, [currentPage]);

  const onEditClick = useCallback(
    (id) => {
      history.push(`${history.location.pathname}/edit/${id}`, { rootPathname: '/reports', pageNumber: currentPage });
    },
    [history, currentPage]
  );

  const onValidateClick = useCallback(
    (id, name, validated) => {
      const toggleValidated = !validated;

      setLoadingActions(true);

      axios
        .patch(`/api/reports/validate-report/${id}`, { validated: toggleValidated }, getRequestHeaders())
        .then(() => {
          toast.success(`Raportul [${name}] a fost ${toggleValidated ? 'validat' : 'invalidat'} cu succes`);
          setReports(prevReports => {
            const newReports = [...prevReports].map(report => report._id === id ? ({
              ...report,
              validated: toggleValidated,
            }) : report);
            return newReports;
          })
        })
        .catch(catchHandlerBinded)
        .finally(() => {
          setLoadingActions(false);
        });
    },
    [catchHandlerBinded]
  );

  const onDownloadClick = useCallback(
    (id, name, emplacementName, date) => {
      const dateObject = (new Date(date));

      setLoadingActions(true);

      axios
        .get(
          `/api/reports/download-report/${id}`,
          getRequestHeaders({ requestDownloadFilesFlag: true })
        )
        .then((res) => {
          const type = res.headers["content-type"];
          const blob = new Blob([res.data], { type, encoding: "UTF-8" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `${name}_${emplacementName}_${dateObject.toLocaleDateString()}`;
          link.click();
          link.remove();
        })
        .catch(catchHandlerBinded)
        .finally(() => {
          setLoadingActions(false);
        });
    },
    [catchHandlerBinded]
  );

  const getRows = useCallback(
    () =>
      reports.length ? reports.map(
        ({ _id, serialNumber, name, type, emplacement, canModifyFlag, blocked, validated, date }, id) => (
          <tr key={`reports-row-${id}`}>
            <td>{serialNumber}</td>
            <td>{name}</td>
            <td>{reportsConfigsTypeToLabel[type]}</td>
            <td>{emplacement.name}</td>
            {isAdminFlag ? (
              <td className="text-center">
                <Button
                  disabled={loadingActions}
                  color={validated ? "success" : "danger"}
                  size="sm"
                  onClick={onValidateClick.bind(null, _id, name, validated)}
                >
                  {validated ? (
                    <BsCheckLg />
                  ) : (
                    <BsXLg />
                  )}
                </Button>
              </td>
            ) : null}
            <td className="text-center">
              <Button
                disabled={loadingActions}
                color="info"
                size="sm"
                onClick={onDownloadClick.bind(null, _id, name, emplacement.name, date)}
              >
                <BsDownload />
              </Button>
            </td>
            {isAdminFlag || isEmployeeFlag ? (
              <td className="text-center">
                {isAdminFlag || (isEmployeeFlag && canModifyFlag && !validated && !blocked) ? (
                  <Button
                    disabled={loadingActions}
                    color="warning"
                    size="sm"
                    onClick={onEditClick.bind(null, _id)}
                  >
                    <BsPencilFill />
                  </Button>
                ) : null}
              </td>
            ) : null}
          </tr>
        )
      ) : (
        <tr>
          <td colSpan={isAdminFlag ? 7 : (isEmployeeFlag ? 6 : 5)} className="text-center">
            <span>Fara rezultat</span>
          </td>
        </tr>
      ),
    [isAdminFlag, isEmployeeFlag, reports, loadingActions]
  );

  const onSearchChange = useCallback((event) => {
    const { target = {} } = event;
    const { value } = target;
    setSearchValue(() => value);
  }, []);

  const handleSearch = useCallback((event) => {
    const { type, key } = event;

    if(type === 'click' || (type === 'keypress' && key === 'Enter')) {
      getData(1, searchValue)
    }
  }, [searchValue]);

  return (
    <Container className="mt-5 mb-5" style={{ maxWidth: "1400px" }}>
      <Row className="mt-5">
        <Col className="d-flex justify-content-between align-items-center">
          <span className="">
            <h3>Rapoarte ({totalCount})</h3>
          </span>
        </Col>
      </Row>
      <Row className="mt-1 justify-content-end">
        <Col className="col-3 d-inline-flex">
          <InputGroup>
            <Input
              type="text"
              placeholder="Search..."
              onChange={onSearchChange}
              onKeyPress={handleSearch}
              value={searchValue}
            />
            <Button
              disabled={loadingActions || loading}
              color="secondary"
              size="sm"
              onClick={handleSearch}
            >
              <BsSearch />
            </Button>
          </InputGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col>
          <Table hover>
            <thead>
              <tr>
                <th>Numar raport</th>
                <th>Titlu</th>
                <th>Tip</th>
                <th>Amplasament</th>
                {isAdminFlag ? (
                  <th className="text-center">Validare</th>
                ) : null}
                <th className="text-center">Descarcare</th>
                {isAdminFlag || isEmployeeFlag ? (
                  <th className="text-center">Modificare</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={isAdminFlag ? 7 : (isEmployeeFlag ? 6 : 5)} className="text-center">
                    <Spinner color="dark" style={{ width: "3rem", height: "3rem" }} />
                  </td>
                </tr>
              ) : (
                getRows()
              )}
            </tbody>
          </Table>
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            onSetPage={setCurrentPage}
          />
        </Col>
      </Row>
    </Container>
  );
})

export default Reports;
