// Packages
import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Spinner } from "reactstrap";
import axios from "axios";
// Pages
// auth
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ActivateUser from "./pages/ActivateUser";
import ResetPassword from "./pages/ResetPassword";
import Logout from "./pages/Logout";
// operations
// managing
import Profile from "./pages/Profile";
import ChangePassword from "./pages/ChangePassword";
import ReportsConfigs from "./pages/ReportsConfigs";
import AddReportsConfig from "./pages/AddReportsConfig";
import EditReportsConfig from "./pages/EditReportsConfig";
import EditReportsConfigPermissions from "./pages/EditReportsConfigPermissions";
import EditReportsConfigSerialNumbers from "./pages/EditReportsConfigSerialNumbers";
import Emplacements from "./pages/Emplacements";
import AddEmplacement from "./pages/AddEmplacement";
import EditEmplacement from "./pages/EditEmplacement";
import UsersManagement from "./pages/UsersManagement";
import EditUser from "./pages/EditUser";
import Register from "./pages/Register";
import Logging from "./pages/Logging";
// general
import Reports from "./pages/Reports";
import EditReport from "./pages/EditReport";
import Files from "./pages/Files";
// home
import Landing from "./pages/Landing";
import Dashboard from "./pages/Dashboard";
// not found
import NotFound from "./pages/NotFound";
// Components
import NavigationBar from "./components/NavigationBar";
import ExclusivePublicRoute from "./components/ExclusivePublicRoute";
import FallbackRoute from "./components/FallbackRoute";
import PrivateRoute from "./components/PrivateRoute";
// Utils
import {
  catchHandler,
  getCsrfToken,
  headers,
  setAudienceHeader,
  userRoles,
  setCsrfHeaders
} from "./utils";
// Styling
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

const App = React.memo((props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.interceptors.response.use(null, (error) => {
      const { config, response } = error;
      if (config && response && response.status === 403 && response.data && response.data.ctk) {
        return getCsrfToken().then(token => {
          config.headers[headers.csrf] = token;
          return axios.request(config);
        });
      }

      return Promise.reject(error);
    });
    setAudienceHeader();
    getCsrfToken()
      .then(token => setCsrfHeaders(token))
      .catch(catchHandler)
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className="app-container">
      <NavigationBar />
      {
        loading ? (
          <Spinner color="dark" style={{
            position: "absolute",
            top: "calc(50% - 3rem)",
            left: "calc(50% - 3rem)",
            width: "6rem",
            height: "6rem"
          }} />
        ) : (
          <Switch>
            {/* AUTH ROUTES */}
            <ExclusivePublicRoute exact path="/login" component={Login} />
            <ExclusivePublicRoute exact path="/forgot-password" component={ForgotPassword} />
            <ExclusivePublicRoute exact path="/activate-user/:token" component={ActivateUser} />
            <ExclusivePublicRoute exact path="/reset-password/:token" component={ResetPassword} />
            <PrivateRoute exact path="/logout" component={Logout} />
            {/* MANAGING ROUTES */}
            <PrivateRoute exact path="/profile" component={Profile} />
            <PrivateRoute exact path="/change-password" component={ChangePassword} />
            <PrivateRoute exact path="/reports-configs" component={ReportsConfigs} accessRoles={[userRoles.ADMIN]} />
            <PrivateRoute exact path="/reports-configs/add" component={AddReportsConfig} accessRoles={[userRoles.ADMIN]} />
            <PrivateRoute exact path="/reports-configs/edit/:id" component={EditReportsConfig} accessRoles={[userRoles.ADMIN]} />
            <PrivateRoute exact path="/reports-configs/edit-permissions/:id" component={EditReportsConfigPermissions} accessRoles={[userRoles.ADMIN]} />
            <PrivateRoute exact path="/reports-configs/edit-serial-numbers/:id" component={EditReportsConfigSerialNumbers} accessRoles={[userRoles.ADMIN]} />
            <PrivateRoute exact path="/emplacements" component={Emplacements} accessRoles={[userRoles.ADMIN]} />
            <PrivateRoute exact path="/emplacements/add" component={AddEmplacement} accessRoles={[userRoles.ADMIN]} />
            <PrivateRoute exact path="/emplacements/edit/:id" component={EditEmplacement} accessRoles={[userRoles.ADMIN]} />
            <PrivateRoute exact path="/users-management" component={UsersManagement} accessRoles={[userRoles.ADMIN]} />
            <PrivateRoute exact path="/users-management/edit/:id" component={EditUser} accessRoles={[userRoles.ADMIN]} />
            <PrivateRoute exact path="/register" component={Register} accessRoles={[userRoles.ADMIN]} />
            <PrivateRoute exact path="/logging" component={Logging} accessRoles={[userRoles.ADMIN]} />
            {/* GENERAL ROUTES */}
            <PrivateRoute exact path="/reports" component={Reports} />
            <PrivateRoute exact path="/reports/edit/:id" component={EditReport} />
            <PrivateRoute exact path="/files" component={Files} />
            {/* HOME ROUTE */}
            <FallbackRoute exact path="/" main={Dashboard} second={Reports} fallback={Landing} accessRoles={[userRoles.ADMIN, userRoles.EMPLOYEE]} />
            {/* NOT FOUND ROUTE */}
            <Route path="*" component={NotFound} />
          </Switch>
        )
      }
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
});

export default App;
