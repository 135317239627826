// WARNING: keep this value in sync with the server value
export const userRoles = {
  ADMIN: "admin",
  EMPLOYEE: "employee",
  AUTHORITY: "authority",
};

export const userRoleToLabel = {
  [userRoles.ADMIN]: "Administrator",
  [userRoles.EMPLOYEE]: "Angajat Mol",
  [userRoles.AUTHORITY]: "Angajat Autoritati",
};

export const userRoleOptions = {
  [userRoles.ADMIN]: { value: userRoles.ADMIN, label: userRoleToLabel[userRoles.ADMIN] },
  [userRoles.EMPLOYEE]: { value: userRoles.EMPLOYEE, label: userRoleToLabel[userRoles.EMPLOYEE] },
  [userRoles.AUTHORITY]: { value: userRoles.AUTHORITY, label: userRoleToLabel[userRoles.AUTHORITY] },
};

export function getUserEmail() {
  const email = localStorage.getItem("email");

  if (!email) {
    return "";
  }

  return email;
}

export function getUserName() {
  const name = localStorage.getItem("name");

  if (!name) {
    return "";
  }

  return name;
}

export function getUserRole() {
  const role = localStorage.getItem("role");

  if (!role) {
    return "";
  }

  return role;
}

export function processUserName({ name = "", email = "" }) {
  return !name ? email : `${name} (${email})`;
}

