import React, { useCallback, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
// Utils
import {
  isAuthenticated,
  userHasAccess,
  userRoles,
  getUserEmail,
} from "../utils";
// Styling

const NavigationBar = React.memo((props) => {
  const [isOpen, setIsOpen] = useState(false);
  const isAuthFlag = isAuthenticated();

  const toggle = () => setIsOpen(!isOpen);

  const getAuthRoutes = useCallback(
    () =>
      isAuthFlag ? (
        <NavItem>
          <NavLink tag={Link} to="/logout">
            Logout ({getUserEmail()})
          </NavLink>
        </NavItem>
      ) : (
        <NavItem>
          <NavLink tag={Link} to="/login">
            Login
          </NavLink>
        </NavItem>
      ),
    [isAuthFlag]
  );

  const getManagingRoutes = useCallback(() => {
    if (!isAuthFlag) return null;

    const isAdmin = userHasAccess([userRoles.ADMIN]);

    if (!isAdmin) {
      return (
        <NavItem>
          <NavLink tag={Link} to="/profile">
            Profil
          </NavLink>
        </NavItem>
      );
    }

    return (
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
          Administrare
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem tag={Link} to="/profile">
            Profil
          </DropdownItem>
          <DropdownItem tag={Link} to="/reports-configs">
            Configurare Rapoarte
          </DropdownItem>
          <DropdownItem tag={Link} to="/emplacements">
            Configurare Amplasamente
          </DropdownItem>
          <DropdownItem tag={Link} to="/users-management">
            Management Utilizatori
          </DropdownItem>
          <DropdownItem tag={Link} to="/register">
            Inroleaza utilizator
          </DropdownItem>
          <DropdownItem tag={Link} to="/logging">
            Loguri
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }, [isAuthFlag]);

  const getGeneralRoutes = useCallback(() => {
    if (!isAuthFlag) return null;

    const isAuthorityFlag = userHasAccess([userRoles.AUTHORITY]);

    return (
      <>
        {
          !isAuthorityFlag ? (
            <NavItem>
              <NavLink tag={Link} to="/">
                Dashboard
              </NavLink>
            </NavItem>
          ) : null
        }
        <NavItem>
          <NavLink tag={Link} to="/reports">
            Rapoarte
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to="/files">
            Fisiere
          </NavLink>
        </NavItem>
      </>
    );
  }, [isAuthFlag]);

  return (
    <div>
      <Navbar color="dark" dark expand="md">
        <NavbarBrand tag={Link} to="/">
          <img src="/logo.png" style={{maxHeight: "24px"}} />
          <img src="/logo-cnair.png" style={{maxHeight: "56px", position: "absolute", top: "0px"}} />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            {getGeneralRoutes()}
            {getManagingRoutes()}
            {getAuthRoutes()}
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
});

export default withRouter(NavigationBar);
