// Packages
import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
// Styling
import "./Landing.css";

const Landing = React.memo((props) => (
  <>
    <div className="background-container"></div>
    <Container className="mt-5 mb-5 text-center">
      <Row className="mt-5">
        <Col className="mt-5">
          <h2 className="mx-auto mt-5">
            Bine ati venit
          </h2>
          <h4 className="mt-3">
            Va rugam sa va logati pentru a folosi aplicatia.
          </h4>
          <Button className="mt-3" tag={Link} color="primary" to={`/login`}>
            Login
          </Button>
        </Col>
      </Row>
      <div className="bottom-message w-100 text-light text-center">Instrument dezvoltat in concordanta cu specificatiile contractelor de concesiune de servicii in vederea utilării / dotării, operării și întreținerii spațiilor pentru servicii aflate pe autostrăzile din România,  deschise traficului rutier, situate pe rutele Nădlac – Sibiu (A1), Pitești - București (A1)  și Cernavodă – Constanța (A2)</div>
    </Container>
  </>
));

export default Landing;
