// Packages
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import { BsX, BsPlusLg } from "react-icons/bs";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  Card,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import axios from "axios";
// Utils
import {
  catchHandler,
  getRequestHeaders,
  reportsConfigsTypeOptions,
  getSelectStyles
} from "../utils";
// Styling
import "./EditReportsConfig.css";

const EditReportsConfig = React.memo((props) => {
  const { id: reportConfigId } = useParams();
  const rootPathname = useMemo(() => "/reports-configs", []);
  const [loading, setLoading] = useState(true);
  const [fields, setFields] = useState({
    name: "",
    type: "",
  });
  const [errors, setErrors] = useState({});
  const [sections, setSections] = useState([{ name: "", fields: [] }]);
  const history = useHistory();
  const catchHandlerBinded = catchHandler.bind(null, history);

  useEffect(() => {
    axios
      .get(`/api/reports-configs/get-reports-config/${reportConfigId}`, getRequestHeaders())
      .then((res) => {
        const { data = {} } = res;
        const { sections = [], ...fields } = data;
        setFields(fields);
        setSections(sections);
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();

      setLoading(true);
      setErrors({});

      axios
        .patch(`/api/reports-configs/edit-reports-config/${reportConfigId}`, {...fields, sections}, getRequestHeaders())
        .then((res) => {
          const { data = {} } = res;
          const { name = "" } = data;
          toast.success(`Raportul [${name}] a fost modificat cu succes`);
          history.push(rootPathname);
        })
        .catch((err) => {
          const { name = "", type = "", section = "" } = catchHandler(history, err);
          setErrors({ name, type, section });
          setLoading(false);
        });
    },
    [history, fields, sections, rootPathname]
  );

  const onCancel = useCallback(() => {
    history.push(rootPathname);
  }, [history, rootPathname]);

  const onInputChange = useCallback((event) => {
    const { target = {} } = event;
    const { name, value } = target;
    setFields((prevFields) => ({ ...prevFields, [name]: value }));
  }, []);

  const onSelectChange = useCallback((option, action) => {
    const { value = "" } = option || {};
    const { name = "" } = action || {};
    setFields((prevFields) => ({ ...prevFields, [name]: value }));
  }, []);

  const onAddSection = useCallback((sectionId, fieldId) => {
    setSections(prevSections => {
      const currentSections = [...prevSections];
      currentSections.splice(sectionId, 0, { name: "", fields: [] });
      if (fieldId >= 0) {
        currentSections[sectionId].fields = currentSections[sectionId - 1].fields.splice(fieldId);
      }
      return currentSections;
    })
  }, []);

  const onRemoveSection = useCallback((sectionId) => {
    setSections(prevSections => {
      const currentSections = [...prevSections];
      if (sectionId > 0) {
        const prevSection = currentSections[sectionId - 1];
        const currentSection = currentSections[sectionId];
        prevSection.fields.push(...currentSection.fields);
      }
      currentSections.splice(sectionId, 1);
      if (!currentSections.length) {
        currentSections.push({ name: "", fields: [] });
      }
      return currentSections;
    });
  }, []);

  const onAddField = useCallback((sectionId, fieldId) => {
    if (sectionId < 0) return;

    setSections(prevSections => {
      const currentSections = [...prevSections];
      const section = currentSections[sectionId];
      section.fields.splice(fieldId < 0 ? section.fields.length : fieldId, 0, { name: "" });
      return currentSections;
    })
  }, []);

  const onRemoveField = useCallback((sectionId, fieldId) => {
    setSections(prevSections => {
      const currentSections = [...prevSections];
      const fields = currentSections[sectionId].fields;
      fields.splice(fieldId, 1);
      return currentSections;
    });
  }, []);

  const onSectionNameChange = useCallback((id, event) => {
    const { target = {} } = event;
    const { value } = target;

    setSections(prevSections => {
      const newSections = [...prevSections];
      newSections[id].name = value;
      return newSections;
    });
  }, []);

  const onSectionFieldNameChange = useCallback((sectionId, fieldId, event) => {
    const { target = {} } = event;
    const { value } = target;

    setSections(prevSections => {
      const newSections = [...prevSections];
      newSections[sectionId].fields[fieldId].name = value;
      return newSections;
    });
  }, []);

  let fieldsCounter = 0;

  return (
    <Container className="mt-5 mb-5">
      <Row>
        <Col sm="12" md={{ size: 10, offset: 1 }}>
          <Card className="p-5 bg-light shadow-sm">
            <h2 className="mb-5 mx-auto text-center">Modificare Raport</h2>
            <Form>
              <FormGroup>
                <Label for="name">Titlu</Label>
                <Input
                  id="name"
                  name="name"
                  type="text"
                  invalid={Boolean(errors["name"])}
                  value={fields["name"]}
                  onChange={onInputChange}
                />
                <FormFeedback>{errors["name"]}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="type">Ciclicitate</Label>
                <Select
                  name="type"
                  className={Boolean(errors["type"]) ? "is-invalid" : ""}
                  placeholder="Alegeti o optiune"
                  noOptionsMessage={() => (<span>Fara rezultat</span>)}
                  isClearable={true}
                  onChange={onSelectChange}
                  styles={getSelectStyles(Boolean(errors["type"]))}
                  value={fields["type"] ? reportsConfigsTypeOptions[fields["type"]] : null}
                  options={Object.values(reportsConfigsTypeOptions)}
                />
                <FormFeedback>{errors["type"]}</FormFeedback>
              </FormGroup>
              {
                sections.map((section, sectionId) => (
                  <React.Fragment key={`section-row-key-${sectionId}`}>
                    <hr/>
                    <FormGroup className="form-group-relative">
                      <Label><BsX className="icon-cursor-pointer" onClick={onRemoveSection.bind(null, sectionId)} /> <strong>Sectiunea {sectionId + 1}</strong></Label>
                      <Input
                        type="textarea"
                        value={section.name}
                        onChange={onSectionNameChange.bind(null, sectionId)}
                      />
                      <UncontrolledDropdown size="sm" className="add-buttons">
                        <DropdownToggle color="success">
                          <BsPlusLg />
                        </DropdownToggle>
                        <DropdownMenu container="body">
                          <DropdownItem onClick={onAddSection.bind(null, sectionId, -1)}>
                            Adaugare Sectiune Sus
                          </DropdownItem>
                          <DropdownItem onClick={onAddSection.bind(null, sectionId + 1, 0)}>
                            Adaugare Sectiune Jos
                          </DropdownItem>
                          <DropdownItem disabled={!Boolean(sectionId)} onClick={onAddField.bind(null, sectionId - 1, -1)}>
                            Adaugare Camp Sus
                          </DropdownItem>
                          <DropdownItem onClick={onAddField.bind(null, sectionId, 0)}>
                            Adaugare Camp Jos
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </FormGroup>
                    {
                      section.fields.map((field, fieldId) => (
                        <FormGroup className="form-group-relative" key={`section-${sectionId}-field-key-${fieldId}`}>
                          <Label><BsX className="icon-cursor-pointer" onClick={onRemoveField.bind(null, sectionId, fieldId)} /> Campul {++fieldsCounter}</Label>
                          <Input
                            type="textarea"
                            value={field.name}
                            onChange={onSectionFieldNameChange.bind(null, sectionId, fieldId)}
                          />
                          <UncontrolledDropdown size="sm" className="add-buttons">
                            <DropdownToggle color="success">
                              <BsPlusLg />
                            </DropdownToggle>
                            <DropdownMenu container="body">
                              <DropdownItem onClick={onAddSection.bind(null, sectionId + 1, fieldId)}>
                                Adaugare Sectiune Sus
                              </DropdownItem>
                              <DropdownItem onClick={onAddSection.bind(null, sectionId + 1, fieldId + 1)}>
                                Adaugare Sectiune Jos
                              </DropdownItem>
                              <DropdownItem onClick={onAddField.bind(null, sectionId, fieldId)}>
                                Adaugare Camp Sus
                              </DropdownItem>
                              <DropdownItem onClick={onAddField.bind(null, sectionId, fieldId + 1)}>
                                Adaugare Camp Jos
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </FormGroup>
                      ))
                    }
                  </React.Fragment>
                ))
              }
              <FormFeedback style={errors["section"] ? {display: "block"} : {}}>{errors["section"]}</FormFeedback>
              <FormGroup className="mt-5">
                <Button
                  color="primary"
                  className="float-right"
                  onClick={onSubmit}
                  disabled={loading}
                >
                  Salvare
                </Button>
                <Button
                  outline
                  color="secondary"
                  className="float-left"
                  onClick={onCancel}
                >
                  Renuntare
                </Button>
              </FormGroup>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  )
});

export default EditReportsConfig;
