// Packages
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  Card,
} from "reactstrap";
import axios from "axios";
// Utils
import { catchHandler, getRequestHeaders } from "../utils";
// Styling
import "./Profile.css";

const Profile = React.memo((props) => {
  const rootPathname = useMemo(() => "/", []);
  const [loading, setLoading] = useState(true);
  const [fields, setFields] = useState({
    email: "",
    name: ""
  });
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const catchHandlerBinded = catchHandler.bind(null, history);

  useEffect(() => {
    axios
      .get(`/api/users/get-profile`, getRequestHeaders())
      .then((res) => {
        const { data = {} } = res;
        setFields(data);
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();

      setLoading(true);
      setErrors({});

      axios
        .patch(`/api/users/edit-profile`, fields, getRequestHeaders())
        .then(() => {
          toast.success("Profilul a fost modificat cu succes");
          history.push(rootPathname);
        })
        .catch((err) => {
          const { name = "", role = "" } = catchHandler(history, err);
          setErrors({ name, role });
          setLoading(false);
        });
    },
    [history, fields, rootPathname]
  );

  const onCancel = useCallback(() => {
    history.push(rootPathname);
  }, [history, rootPathname]);

  const onChangePassword = useCallback(() => {
    history.push("/change-password");
  }, [history]);

  const onInputChange = useCallback((event) => {
    const { target = {} } = event;
    const { name, value } = target;
    setFields((prevFields) => ({ ...prevFields, [name]: value }));
  }, []);

  return (
    <Container className="mt-5 mb-5">
      <Row>
        <Col sm="12" md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
          <Card className="p-5 bg-light shadow-sm">
            <h2 className="mb-5 mx-auto text-center">Modificare Profil</h2>
            <Form>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  id="email"
                  name="email"
                  type="text"
                  value={fields["email"]}
                  disabled={true}
                />
              </FormGroup>
              <FormGroup>
                <Label for="name">Nume</Label>
                <Input
                  id="name"
                  name="name"
                  type="text"
                  invalid={Boolean(errors["name"])}
                  value={fields["name"]}
                  onChange={onInputChange}
                />
                <FormFeedback>{errors["name"]}</FormFeedback>
              </FormGroup>
              <FormGroup className="text-center mt-4">
                <Button
                  color="info"
                  className="w-75"
                  onClick={onChangePassword}
                >
                  Schimbare Parola
                </Button>
              </FormGroup>
              <FormGroup className="mt-5">
                <Button
                  color="primary"
                  className="float-right"
                  onClick={onSubmit}
                  disabled={loading}
                >
                  Salvare
                </Button>
                <Button
                  outline
                  color="secondary"
                  className="float-left"
                  onClick={onCancel}
                >
                  Renuntare
                </Button>
              </FormGroup>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  )
});

export default Profile;
